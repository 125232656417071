module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Schmooze Dating","short_name":"Schmooze","description":"Like Memes? We can find you Dates to enjoy it with.","lang":"en","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"src/images/logo_1.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fad72874d555da0d8b3009734fc7e3b5"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"7b414a1e6aa10efb010c33d5dbd01bf3","enableOnDevMode":true,"mixpanelConfig":null,"pageViews":null,"trackPageViewsAs":null,"getPageViewTransformerFn":null},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
